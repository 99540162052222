import React, { Component } from 'react';


class About extends Component {
  render() {
    return (
      <div>
          <h1>
              About Page

              <div>Basketball Icon made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" 			    title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" 			    title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div>
          </h1>
      </div>
    );
  }
}

export default About;